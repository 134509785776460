import React from "react";

import {

  Create,
  SimpleForm,
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  PasswordInput,
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Edit,
  EditButton
} from "react-admin";

import EditTopBar from "./EditTopBar";

const owners = [
  { id: "STORE", name: "Store" },
  { id: "USER", name: "User" }
];

const MyTasksCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
      <TextInput source="name" />
        <PasswordInput  source="password" />
        <AutocompleteInput source="owner" choices={owners} />
        <ReferenceInput label="Store" source="store" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="User" source="user" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="active" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

const MyTasksEdit = (props) => {
  return (
    <Edit {...props} actions={<EditTopBar />}>
      <SimpleForm>
      <TextInput source="name" />
        <PasswordInput  source="password" />
        <AutocompleteInput source="owner" choices={owners} />
        <ReferenceInput label="Store" source="store" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="User" source="user" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

const MyTasksList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        {/* <TextField source="id" />  */}

        <TextField source="name" />
        <TextField source="owner" />
        <ReferenceField label="Store" source="store" reference="stores">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="User" source="user" reference="users">
          <TextField source="name" />
        </ReferenceField>
        
        <BooleanField source="active" />
        <EditButton label="Edit" basePath="/my_tasks" />
      </Datagrid>
    </List>
  );
};

export { MyTasksCreate, MyTasksEdit, MyTasksList };
