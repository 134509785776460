import React from "react";

import {
  Create,
  SimpleForm,
  TextInput,  
  List,
  AutocompleteInput,
  Datagrid,
  TextField,    
  Edit,
  EditButton,
  required
} from "react-admin";

import EditTopBar from "./EditTopBar";

const task_types = [
  { id: "Repeated", name: "Repeated" },
  { id: "Todo", name: "Todo" }
];

const TaskGroupsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>        
        <TextInput source="name" />
        <AutocompleteInput source="tasks_type" choices={task_types} validate={required()}  />
      </SimpleForm>
    </Create>
  );
};

const TaskGroupsEdit = (props) => {
  return (
    <Edit {...props} actions={<EditTopBar />}>
      <SimpleForm>        
        <TextInput source="name" />
        <AutocompleteInput source="tasks_type" choices={task_types} validate={required()}  />
        
      </SimpleForm>
    </Edit>
  );
};

const TaskGroupsList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="Admin Task Groups" >
      <Datagrid expand={<PostPanel />} isRowExpandable={row => true}>                
        <TextField source="name" />
        <TextField source="tasks_type" />
        
        <EditButton label="Edit" basePath="/task_groups" />
      </Datagrid>
    </List>
  );
};

const PostPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.body }} />
);

// const Aside = () => (
//   <div style={{ width: 200, margin: '1em' }}>
//       <Typography variant="h6">Post details</Typography>
//       <Typography variant="body2">
//           Posts will only be published once an editor approves them
//       </Typography>
//   </div>
// );
export { TaskGroupsCreate, TaskGroupsEdit, TaskGroupsList };
