
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import { Menu, MenuItemLink } from 'react-admin';

const Scheduler = () => (
    <Card>
        <Title title="My Page" />
        <CardContent>
            <Menu>
			<MenuItemLink to="/products" primaryText="Products"  />
			<MenuItemLink to="/users" primaryText="Users"  />
			</Menu>
        </CardContent>
    </Card>
);

export default Scheduler;