import React from "react";
import {  Layout } from "react-admin";
// In CustomLayout.js


 import TreeMenu from '@bb-tech/ra-treemenu';
 
const CustomLayout = (props) => {
    // TreeMenu.defaultProps.dashboardlabel = 'My Dashboard';
    
    return (
        
        <Layout {...props}  style={{}} menu={TreeMenu}>
        </Layout>
        
    )
};

export default CustomLayout;