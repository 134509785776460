

import { httpGet } from "./helperHttp";

/**
 * 
 * @param {*} path 
 * @param {*} query 
 * @param {*} headers 
 */
export function getStores(query, headers) {
 
    return httpGet("stores", query, headers);        

}

/**
 * 
 * @param {*} path 
 * @param {*} query 
 * @param {*} headers 
 */
 export function getTaskGroups(query, headers) {
 
    return httpGet("task_groups", query, headers);        

}

export function findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
}

