import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = process.env.REACT_APP_API_HOST;

const httpClient = fetchUtils.fetchJson;
const getHeaders = () => {
  const token = localStorage.getItem("token");
  const headers = new Headers({ Accept: "application/json" });
  headers.set("X-Custom-Header", "foobar");
  headers.set("Content-Range", "xxx");
  headers.set("Authorization", `Bearer ${token}`);
  return headers;
};
const dataProvider ={
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {
      headers: getHeaders(),
    };
    return (
      httpClient(url, options)
        // .then( ({ headers, json }) => {
        .then(({ headers, json }) => {
          console.log("Content-Range:", headers.get("content-range"));
          //console.log("Headers: ", headers);
          let total = parseInt(headers.get("content-range").split("/").pop(), 10); 
          //total=1000;
          return {
            data: json.map((resource) => ({ ...resource, id: resource._id })),
            total
          };
        })
    );
  },

  getOne: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const options = {
      headers: getHeaders(),
    };
    return httpClient(url, options).then(({ json }) => {
      if(!json) return null
      return {
        data: { ...json, id: json._id },
      };
    });
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {
      headers: getHeaders(),
    };
    return httpClient(url, options).then(({ json }) => {
      return {
        data: json.map((resource) => ({ ...resource, id: resource._id })),
      };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {
      headers: getHeaders(),
    };
    return httpClient(url, options).then(({ headers, json }) => {
      return {
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    });
  },

  update: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const options = {
      headers: getHeaders(),
      method: "PUT",
      body: JSON.stringify(params.data),
    };
    return httpClient(url, options).then(({ json }) => {
      return {
        ...json,
        id: json._id,
      };
    });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {
      headers: getHeaders(),
      method: "PUT",
      body: JSON.stringify(params.data),
    };
    return httpClient(url, options).then(({ json }) => {
      return {
        data: json,
      };
    });
  },

  create: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = {
      headers: getHeaders(),
      method: "POST",
      body: JSON.stringify(params.data),
    };
    return httpClient(url, options).then(({ json }) => {
      return {
        data: { ...params.data, id: json._id },
      };
    });
  },

  delete: (resource, params) => {
    console.log("Single: ", params);
    const url = `${apiUrl}/${resource}/${params.id}`;
    const options = {
      headers: getHeaders(),
      method: "DELETE",
      body: JSON.stringify(params.id),
    };
    return httpClient(url, options).then(({ json }) => {
      return {
        ...json,
        id: json._id,
      };
    });
  },

  deleteMany: (resource, params) => {
    console.log("Many: ", params);
    // const query = { filter: JSON.stringify({ id: params.ids }), };
    const ids = params.ids[0];
    const url = `${apiUrl}/${resource}/${ids}`;
    const options = {
      headers: getHeaders(),
      method: "DELETE",
      body: JSON.stringify(params.ids),
    };

    return httpClient(url, options).then(({ json }) => {
      console.log(json);
      return {
        data: params.ids
      };
    });
  },
};

export default dataProvider;