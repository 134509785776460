import React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  List,
  Datagrid,
  TextField,
  PasswordInput,
  BooleanField,
  Edit,
  EditButton
} from "react-admin";

import EditTopBar from "./EditTopBar";

const timeZones = [
  { id: "EST", name: "Eastern Time (North America)" },
  { id: "CST", name: "Central Standard Time (North America)" },
  { id: "MDT", name: "Mountain Daylight Time (North America)" },
  { id: "PST", name: "Pacific Standard Time (North America)" },
];

const StoresCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="location" />
        <TextInput source="name" />
        <PasswordInput  source="password" />
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip_code" />
        <AutocompleteInput source="time_zone" choices={timeZones} />
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};

const StoresEdit = (props) => {
  return (
    <Edit {...props} actions={<EditTopBar />}>
      <SimpleForm>
        <TextInput source="location" />
        <TextInput source="name" />
        <PasswordInput  source="password" />
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip_code" />
        <AutocompleteInput source="time_zone" choices={timeZones} />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

const StoresList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        {/* <TextField source="id" />  */}
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="adress" />
        <TextField source="city" />
        <TextField source="state" />
        <BooleanField source="active" />
        <EditButton label="Edit" basePath="/stores" />
        {/* <DeleteButton label="Delete" basePath="/stores" /> */}
      </Datagrid>
    </List>
  );
};

export { StoresCreate, StoresEdit, StoresList };
