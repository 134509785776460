import React from 'react';

import { 
    Create, 
    SimpleForm, 
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    List,
    Datagrid,
    TextField,
    NumberField,
    BooleanField,
    Edit, 
    ReferenceField,
    EditButton
} from 'react-admin';

import EditTopBar from "./EditTopBar";

const ProductsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Store" source="store_id" reference="stores">
            <SelectInput optionText="name" />
        </ReferenceInput>      
        <TextInput source="name" />        
        <TextInput source="category" />        
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};


const ProductsEdit = (props) => {
    return (
      <Edit {...props} actions={<EditTopBar basePath="/products" />}>        
        <SimpleForm>      
          
          <ReferenceInput label="Store" source="store_id" reference="stores">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" />
          <TextInput source="category" />
          <BooleanInput source="active" />
        </SimpleForm>
        
      </Edit>
    );
  };

  
const ProductsList = (props) => {
    return (
      <List {...props}>
        <Datagrid>
          
          {/* <TextField source="id" /> */}
          <ReferenceField label="Store" source="store_id" reference="stores">
                <TextField source="name" />
          </ReferenceField>            
          
          <TextField source="name" />
          <TextField source="category" />        
          <BooleanField source="active" />
          <EditButton label="Edit" basePath="/products" />
          {/* <DeleteButton label="Delete" basePath="/products" /> */}
        </Datagrid>
      </List>
    );
  };


export {ProductsCreate, ProductsEdit, ProductsList };