import axios from "axios";

const host = process.env.REACT_APP_API_HOST;
const setHeader = (headers) => {
  const token = localStorage.getItem("token");
  if (!headers) headers = {};
  headers.Authorization = `Bearer ${token}`;
  //headers.location_id = station.location_id;
  //headers.station_id = station.station_id;
  //headers.machine_id = station.machine_id;
  // console.log('headers: ',headers);
  return headers;
};

const composeUrl = (path, query) => {
  let sUrl = `${host}/${path}`;
  if (query) sUrl += `/${query}`;
  return sUrl;
};

function execRequest(options) {
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        //console.log(response);
        resolve(response);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log("401 Error");
        } else {
          reject(err);
        }
      });
  });
}

/**
 * callback(err, response)
 * @param path
 * @param query
 * @param headers

 */
export function httpGet(path, query, headers) {
  const sUrl = composeUrl(path, query);
  const _headers = setHeader(headers);
  const options = {
    method: "get",
    url: sUrl,
    headers: _headers,
  };
  return execRequest(options);

  // return new Promise( (resolve, reject) => {
  //   axios(options)
  //   .then(response => {
  //     console.log(response);
  //     resolve(response);
  //   })
  //   .catch(err => {
  //     if (err.response.status === 401) {
  //       console.log('401 Error');
  //     } else {
  //       reject(err);
  //     }
  //   })
  // });
}

/**
 *
 * @param path
 * @param query
 * @param body
 * @param headers
 * @param callback
 * @returns
 */
export function httpPost(path, query, body, headers) {
  const sUrl = composeUrl(path, query);
  const _headers = setHeader(headers);
  const options = {
    method: "post",
    url: sUrl,
    headers: _headers,
    data: body,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          console.log("401 Error");
        } else {
          reject(err);
        }
      });
  });
}
