import React from 'react';
// import { Card, CardContent, withStyles } from '@material-ui/core';

import { 
    Create, 
    SimpleForm, 
    TextInput,
    BooleanInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Edit,      
    EditButton,
    required,
    Pagination
} from 'react-admin';
import EditTopBar from "./EditTopBar";

const task_types = [
  { id: "Repeated", name: "Repeated" },
  { id: "Todo", name: "Todo" }
];


const getListFilters = (taskGroups) => {
  return (
    [
      <SelectInput label="Task Type" source="task_type" choices={task_types} alwaysOn />,
      <SelectInput label="Task Groups" source="task_group" choices={taskGroups} />,
      <TextInput label="Name" source="name"  />
    ]
  )
}


const DailyTasksDefCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>           
          <AutocompleteInput source="task_type" choices={task_types} validate={required()}  />
          <TextInput source="name" />
          <TextInput source="note" />
          <NumberInput source="display_order" />
          <TextInput source="cron_schedule" />
          <TextInput source="start_hour" />
          <TextInput source="end_hour" />
          <TextInput source="days" />
          <TextInput source="earn_points" />
          <BooleanInput source="prompt_for_user" />
          <BooleanInput source="count_products" />
          <BooleanInput source="active" />

          <ReferenceInput label="Task Group" source="task_group" reference="task_groups">
            <SelectInput optionText="name" />
          </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const DailyTasksDefEdit = (props) => {
    return (
      <Edit {...props} actions={<EditTopBar />}>
        <SimpleForm>      
          <AutocompleteInput source="task_type" choices={task_types} validate={required()} />
          <TextInput source="name" />
          <TextInput source="note" />
          <NumberInput source="display_order" />
          <TextInput source="cron_schedule" />
          <TextInput source="start_hour" />
          <TextInput source="end_hour" />
          <TextInput source="days" />
          <TextInput source="earn_points" />
          <BooleanInput source="prompt_for_user" />
          <BooleanInput source="count_products" />
          <BooleanInput source="active" />

          <ReferenceInput label="Task Group" source="task_group" reference="task_groups">
            <SelectInput optionText="name" />
          </ReferenceInput>
          
        </SimpleForm>
      </Edit>
    );
  };

  const MyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
  
  const DailyTasksDefList = (props) => {
    return (
      <List {...props} bulkActionButtons={false} perPage={25} pagination={<MyPagination />} filters={getListFilters(props.options.taskGroupsFilter)} >
        <Datagrid>
          <TextField source="task_type" />
          <ReferenceField label="Group" source="task_group" reference="task_groups">
                <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />taskGroupsFilter
          <TextField source="display_order" label="order" />
          <TextField source="note" />
          <TextField source="cron_schedule" />
          <TextField source="start_hour" />
          <TextField source="end_hour" />
          <TextField source="days" />
          <TextField source="count_products" />
          <TextField source="active" />
          <EditButton label="Edit" basePath="/daily_tasks_def" />          
        </Datagrid>
      </List>
    );
  };


export {DailyTasksDefCreate, DailyTasksDefEdit, DailyTasksDefList };