import React  from "react";


import { 
  Create, 
  SimpleForm, 
  TextInput,
  BooleanInput,
  SelectInput,  
  ReferenceInput,  
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,      
  EditButton,
  PasswordInput,
  BooleanField,
  Pagination
  
} from 'react-admin';

import EditTopBar from "./EditTopBar";

const getListFilters = (storesFilter) => {
  return (
    [
      <SelectInput label="Stores" source="store_id" choices={storesFilter} alwaysOn />,
      <TextInput label="First Name" source="first_name_like"  />,
      <TextInput label="Last Name" source="last_name_like" alwaysOn />
    ]
  )
}

const UsersCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="user_name" label="User Name" />
        <PasswordInput source="password" />
        <ReferenceInput label="Store" source="store_id" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <TextInput source="role" />
        <TextInput source="email" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};

const UsersEdit = (props) => {
  return (
    <Edit {...props} actions={<EditTopBar />}>
      <SimpleForm>
        <TextInput source="user_name" />
        <PasswordInput source="password" />
        <ReferenceInput label="Store" source="store_id" reference="stores">
          <SelectInput optionText="name" />
        </ReferenceInput>
    
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <TextInput source="role" />
        <TextInput source="email" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const UsersList = (props) => {
  
  return (
    <List {...props} bulkActionButtons={false} perPage={25} pagination={<PostPagination />} filters={getListFilters(props.options.storesFilter)}>
      <Datagrid>
        <ReferenceField label="Store" source="store_id" reference="stores">
          <TextField source="name" />
        </ReferenceField>        
        <TextField source="user_name" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="role" />
        <TextField source="email" />
        <TextField source="date" />
        <EditButton label="Edit" basePath="/users" />        
      </Datagrid>
    </List>
  );
};

export { UsersCreate, UsersEdit, UsersList };
