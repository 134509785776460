
import React from "react";

import { TopToolbar } from "react-admin";

import Button from "@material-ui/core/Button";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ChevronLeft from '@material-ui/icons/ChevronLeft';


const cancelAction = () => {
    //if(confirm("Cancel Changes")){
    window.history.back();
    //}
  };

const EditTopBar = () => {
    return (
        <TopToolbar>
            {/* <ShowButton basePath={basePath} record={data} /> */}
            
            {/* <ShowButton basePath=""  /> */}
            {/* Add your custom actions */}
            {/* <ListButton basePath="" label="Back" icon={<ChevronLeft />} /> */}
           
            
            <Button aria-label="back" variant="outlined" color="secondary"  onClick={cancelAction}>
                <ChevronLeft />
                Back
            </Button>
            
            {/* <IconButton aria-label="Cancel" variant="outlined" color="red" onClick={cancelAction}>
                Back
                <ExitToAppIcon />
            </IconButton> */}            
            
        </TopToolbar>
    )
};

export default EditTopBar;