import React from "react";

import {

  Create,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Edit,
  EditButton
} from "react-admin";

import EditTopBar from "./EditTopBar";


const MyTasksGroupsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="short_name" />
        <NumberInput source="display_order" />
        <ReferenceInput label="My Task" source="my_task" reference="my_tasks">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Task Groups" source="task_group" reference="task_groups">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="start_hour" />
        <TextInput source="end_hour" />
        <TextInput source="days" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};

const MyTasksGroupsEdit = (props) => {
  return (
    <Edit {...props} actions={<EditTopBar />}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="short_name" />
        <NumberInput source="display_order" />
        <ReferenceInput label="My Task" source="my_task" reference="my_tasks">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Task Groups" source="task_group" reference="task_groups">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="start_hour" />
        <TextInput source="end_hour" />
        <TextInput source="days" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

const MyTasksGroupsList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        
        <TextField source="name" />
        <TextField source="short_name" />
        <TextField source="display_order" />
        <ReferenceField label="My Task" source="my_task" reference="my_tasks">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Task Group" source="task_group" reference="task_groups">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="start_hour" />
        <TextField source="end_hour" />
        <TextField source="days" />
        <BooleanField source="active" />
        <EditButton label="Edit" basePath="/my_tasks_groups" />
      </Datagrid>
    </List>
  );
};

export { MyTasksGroupsCreate, MyTasksGroupsEdit, MyTasksGroupsList };
