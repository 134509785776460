import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,  
  Show,
  ShowButton,
  SimpleShowLayout,
  Pagination,
  FunctionField
} from "react-admin";

import CustomDeleteButton from './CustomDeleteButton';

import EditTopBar from "./EditTopBar";
import { findObjectByKey } from "../helpers/helperCommon";

const useStyles = makeStyles({
  store: { color: 'navy', fontWeight: 'bolder' },
});

const GetStoreName = (stores, id) => {
  const obj = findObjectByKey(stores, 'id', id);
  if(obj) return obj.name;
  return "";
}

const MyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const DailyTasksShow = (props) => {
  return (
    <Show {...props} actions={<EditTopBar />}>
      <SimpleShowLayout>
        <FunctionField  label="Store" render={record => {return `${GetStoreName(props.options.storesFilter, record.store_id)}`}}  />        
        <TextField label="User" source="user_full_name" />
        <TextField label="Task" source="name" />
        <TextField source="note" />
        <DateField source="due_date" />
        <DateField source="picked_date" />
        <DateField source="completed_date" />
        <TextField source="earn_points" />
      </SimpleShowLayout>
    </Show>
  );
};


const DailyTasksList = (props) => {  
  const  classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} perPage={25} pagination={<MyPagination />}>
      <Datagrid>
        <FunctionField className={classes.store}  label="Store" render={record => {return `${GetStoreName(props.options.storesFilter, record.store_id)}`}}  />        
  
        <TextField label="User" source="user_full_name" />
        
        <TextField label="Task" source="name" />
       
        <DateField source="picked_date"  showTime={true} />
        <DateField source="completed_date"  showTime={true} />
        <NumberField source="earn_points" />
        <NumberField source="task_type" />
        <ShowButton basePath="/daily_tasks"  />
       
        {/* <DeleteWithConfirmButton confirmTitle="Delete Daily" /> */}
        {/* <CustomDeleteButton basePath="/daily_tasks" undoable={false} type="daily_tasks" field="name" /> */}
        <CustomDeleteButton  undoable={false} type="daily_tasks" field="name" />
      </Datagrid>
    </List>
  );
};

export { DailyTasksShow, DailyTasksList };