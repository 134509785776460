import React, {useState, useEffect} from "react";
import { Admin, Resource } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

// import TreeIcon from '@material-ui/icons/AccountTree';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/StoreMallDirectory';

import dataProvider from "./dataProvider";

import * as Products from "./components/products";
import * as DailyTasksDef from "./components/DailyTasksDef";
import * as DailyTasks from "./components/DailyTasks";
import * as Stores from "./components/Stores";
import * as Users from "./components/Users";
import * as TaskGroups  from "./components/TaskGroups";
import * as MyTasks  from "./components/MyTasks";
import * as MyTasksGroups  from "./components/MyTasksGroups";

import customRoutes from "./customRoutes";
import authProvider from "./authProvider";
import CustomLayout from "./customLayout";

import { getStores, getTaskGroups } from "./helpers/helperCommon";


// import { fetchJson as httpClient } from "./httpClient";
// const myDataProvider = dataProvider('http://localhost:4000/api', httpClient);
const useStyles = makeStyles({
  parent: { color: 'blue', fontWeight: 'bolder' },
});

function App() {

  const [storesFilter, setStoresFilter] = useState([]);
  const [taskGroupsFilter, setTaskGroupsFilter] = useState([]);
  const  classes = useStyles();
  useEffect( () => {
    
    async function GetStores(){
      const result = await getStores();
      const storesFilter = result.data.map(x => {
        return { id: x._id, name: x.name} 
      });
      setStoresFilter(storesFilter);
    }

    async function GetTaskGroups(){
      const result = await getTaskGroups();
      const groupsFilter = result.data.map(x => {
        return { id: x._id, name: x.name} 
      });
      setTaskGroupsFilter(groupsFilter);
     // console.log(taskGroupsFilter);
    }
    
    GetStores();
    GetTaskGroups();
    
  }, []);
  

  return (
    <Admin customRoutes={customRoutes} layout={CustomLayout} dataProvider={dataProvider} authProvider={authProvider} >
      {/* Dummy parent resource with isMenuParent options as true */}
      <Resource name="parent_tasks_admin" className={classes.parent} options={{ "label": "Tasks Admin", "isMenuParent": true }} />
        <Resource name="task_groups" 
          options={{"label": "Task Groups", "menuParent": "parent_tasks_admin", "isMenuParent": false}}
          list={TaskGroups.TaskGroupsList} edit={TaskGroups.TaskGroupsEdit} create={TaskGroups.TaskGroupsCreate} />
        <Resource name="daily_tasks_def" 
          options={{storesFilter, taskGroupsFilter, "label": "Task Def", "menuParent": "parent_tasks_admin", "isMenuParent": false}}
          list={DailyTasksDef.DailyTasksDefList} edit={DailyTasksDef.DailyTasksDefEdit} create={DailyTasksDef.DailyTasksDefCreate} />      
        <Resource name="my_tasks_groups" 
          options={{"label": "My Task Groups", "menuParent": "parent_tasks_admin", "isMenuParent": false}}
          list={MyTasksGroups.MyTasksGroupsList} edit={MyTasksGroups.MyTasksGroupsEdit} create={MyTasksGroups.MyTasksGroupsCreate} />      
        <Resource name="my_tasks" 
          options={{"label": "My Tasks", "menuParent": "parent_tasks_admin", "isMenuParent": false}}
          list={MyTasks.MyTasksList} edit={MyTasks.MyTasksEdit} create={MyTasks.MyTasksCreate} />      
        
      <Resource name="parent_general_admin" options={{ "label": "Office Admin", "isMenuParent": true }} />
        <Resource name="stores" icon={StoreIcon} 
          options={{"label": "Stores", "menuParent": "parent_general_admin", "isMenuParent": false}}
          list={Stores.StoresList} edit={Stores.StoresEdit} create={Stores.StoresCreate}  />
        <Resource name="users" icon={PeopleIcon} 
          options={{storesFilter, "label": "Users", "menuParent": "parent_general_admin", "isMenuParent": false}}
          list={Users.UsersList} edit={Users.UsersEdit} create={Users.UsersCreate} />
        <Resource name="products" 
          options={{"label": "Product", "menuParent": "parent_general_admin", "isMenuParent": false}}
          list={Products.ProductsList} edit={Products.ProductsEdit} create={Products.ProductsCreate} />

      
      <Resource name="parent_operation_admin" options={{ "label": "Operation", "isMenuParent": true }} />
        <Resource name="daily_tasks"  
          options={{storesFilter, taskGroupsFilter,"label": "Task History", "menuParent": "parent_operation_admin", "isMenuParent": false}}
          list={DailyTasks.DailyTasksList} show={DailyTasks.DailyTasksShow}  />
      
    </Admin>
  );
}

export default App;
