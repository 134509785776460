import { httpPost } from "./helpers/helperHttp";

// in src/authProvider.js
const authProvider= {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    // Call api token and get token
    const body = {
      user_id: username,
      password: password,
    };

    const result = await httpPost("token", null, body, null);
    if (result.data) {
      localStorage.setItem("token", result.data.token);
    }
    // accept all username/password combinations
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const token = localStorage.getItem("token");
    return token ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;