// in src/customRoutes.js
import * as React from "react";
import { Route } from 'react-router-dom';
// import { RouteWithoutLayout } from 'react-admin';
import Scheduler from './components/custom/Scheduler';


const customRoutes =  [
    <Route exact path="/foo" component={Scheduler} />
];

export default customRoutes;