import React                    from 'react';
import { DeleteButton }         from 'react-admin';

const CustomDeleteButton = ({ type = 'Item', field, ...props }) => {
  const { record } = props;
  return (
    <DeleteButton
      confirmTitle={`Delete ${type}: ${field ? record[field] : 'this item'} ?`}
      confirmContent={'Are you sure you want to delete this item?'}
      {...props}
    />
  );
}

export default CustomDeleteButton;